import * as React from "react";



import ContactUsForm from "../components/contact-us-form";
import Frame from "../components/frame";
import { HeadMeta } from "../components/head-meta";



import NewYorkMap from "../images/new-york-map.png";
import SaltLakeCityMap from "../images/salt-lake-city-map.png";

import * as styles from "../styles/contact-us.module.scss";

const MapAndAddress = ({ img, name, address }) => {
	return (
		<div className={styles.mapAndAddress}>
			<img src={img} alt={name} />
			<h4>{name}</h4>
			<p>{address}</p>
		</div>
	);
};

export const Head = () => {
	return <HeadMeta title="Contact Us" />;
};
const ContactUsPage = () => {
	return (
		<Frame contained="true">
			<div className={styles.container}>
				<p className={styles.contactUs}>CONTACT US</p>
				<div className={styles.formMapWrapper}>
					<div>
						<MapAndAddress
							img={SaltLakeCityMap}
							name="Salt Lake City"
							address="111 S. Main Street, Suite 2350, Salt Lake City, UT 84111"
						/>
						<MapAndAddress
							img={NewYorkMap}
							name="New York City"
							address="888 7th Ave Suite 2306, New York, NY 10106"
						/>
					</div>
					<ContactUsForm showTitle={false} />
				</div>
			</div>
		</Frame>
	);
};

export default ContactUsPage;
